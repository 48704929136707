<template>
    <div>
      <!-- <div class="d-flex justify-content-start align-items-center my-10">
        Toplam {{ totalItems }} müşteri bulundu.
      </div> -->
      <div
        class="d-flex align-items-center justify-content-between search-list-area"
      >
        <div class="d-flex align-items-center search-input mb-4">
          <input placeholder="Ara.." v-model="search" class="form-control mr-2" />
          <select v-model="list_length" class="form-control ml-2">
            <option value="5" selected>Seçiniz</option>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
        </div>
        <b-button variant="danger" @click="bayiEkle = true"><b-icon icon="plus-lg" class="mr-3"></b-icon>Bayi Ekle</b-button>
      </div>
  
      <div class="custom-list">
        <List
          :header="header"
          :data="items"
          :pagination="pagination"
          :loading="loading"
          @updatePage="updatePage"
          moduleName="Mülkler Listesi"
          :moduleCreateEvent="true"
          :moduleSearch="search"
        >
          <template v-slot:item.fiyat="{ item }">
            <p class="text-dark">{{ item.fiyat }} ₺</p>
          </template>
         <template v-slot:item.tip ="{item}">
          <b-badge variant="success" v-if="item.tip === 1">Onaylandı</b-badge>
          <b-badge variant="warning" v-else-if="item.tip === 2">Bekliyor</b-badge>
              <b-badge  variant="danger" v-if="item.tip === 3" @click="openModal = !openModal" style="cursor: pointer;">Onaylanmadı <b-icon  icon="exclamation-circle" class="bg-info ml-1"></b-icon></b-badge>
          </template>
         
          <template v-slot:item.action="{ item }" class="p-0">
            <div class="d-flex justify-content-end">
              <router-link
                :to="`/mulkler/mulk-detay/${item.id}/`"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <button
                  class="btn"
                  :href="href"
                  @click="navigate"
                  ><i class="flaticon2-arrow text-dark p-0"></i
                ></button>
              </router-link>
              <router-link
                :to="`properties/detail`"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <button
                  class="btn"
                  :href="href"
                  @click="navigate"
                  ><i class="flaticon2-trash text-danger p-0"></i
                ></button>
              </router-link>
              
            </div>
          </template>
        </List>
        <!-- Create and Actions Popups -->
      </div>
      
      <DeleteOperation
      />
      <div>

      <b-modal v-model="openFullTextModal" title="BootstrapVue">
        <p class="my-4">Hello from modal!</p>
      </b-modal>
      <b-modal v-model="openModal" title="Onaylanmama Nedeni">
        <p class="my-4">Şu sebeplerden dolayı onaylanmamıştır;<p>Fiyat Pahalı.</p></p>
        <template #modal-footer>
        <button @click="openModal=false" class="btn btn-primary">Tamam</button>
        </template>
      </b-modal>
      <b-modal v-model="bayiEkle" title="Bayi Ekle">
        <div class="row">
        <div class="col-md-12 col-12">
          <div class="form-group">
          </div>
        </div>
        <div class="col-md-12 col-12">
          <div class="form-group">
            <label>Sorumlu Kişi</label>
            <input
              type="text"
              class="form-control form-control-lg"
              name="package"
              placeholder="Ad Soyad"
            />
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label>Telefon Numarası</label>
            <input
              type="text"
              class="form-control form-control-lg"
              name="package"
              placeholder="Telefon Numarası"
            />
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label>E-Posta Adresi</label>
            <input
              type="email"
              class="form-control form-control-lg"
              name="package"
              placeholder="E-posta adresinizi giriniz"
            />
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label>İl</label>
            <input
              type="text"
              class="form-control form-control-lg"
              name="package"
              placeholder="İl"
            />
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label>İlçe</label>
            <input
              type="text"
              class="form-control form-control-lg"
              name="package"
              placeholder="İlçe"
            />
          </div>
        </div>
      </div>
        <template #modal-footer>
        <button @click="bayiEkle=false" class="btn btn-danger">Vazgeç</button>
        <button class="btn btn-success" @click="bayiEklendi ">Ekle</button>
        </template>
      </b-modal>
    </div>
    </div>
  </template>
  
  <style lang="scss" scoped>
  @media (max-width: 990px) {
    .search-list-area {
      flex-direction: column;
      .search-input {
        width: 100%;
        * {
          width: 100%;
        }
      }
    }
    .search-buttons {
      flex-direction: column;
    }
  }
  
  .badges-area {
    display: flex;
    gap: 1rem;
    p {
      font-size: 14px !important;
      font-weight: 600 !important;
      color: $dveb-primary !important;
    }
    .badges {
      display: flex;
      align-items: center;
      gap: 0.2rem;
      .badge {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        padding: 0 !important;
  
        &.green {
          background-color: #44b6ae;
        }
        &.yellow {
          background-color: #ffac00;
        }
        &.red {
          background-color: red;
        }
      }
    }
  }
  .tapu-text {
    span {
      background-color: $dveb-divider;
      padding: 0.2rem 0.5rem;
      border-radius: 0.3rem;
    }
  }
  </style>
  
  <script>
import Swal from 'sweetalert2';

  export default {
    name: "customerList",
    
    data() {
      return {
        search: "",
        filters: "",
        showInsertUpdate: false,
        showDetail: false,
        showImportModal: false,
        resetForm: false,
        confirmCreate: false,
        loading: false,
        badgesEnum: ["green", "yellow", "red"],
        stepNames : ["Hazırlık","Satış","Devir"],
        header: [
          { text: "", value: "id", size: "50px" },
          { text: "Sorumlu Kişi", sortable:true,value: "sorumlukisi" },
          { text: "Telefon No", sortable:true,value: "telefonno" },
          { text: "E-posta", sortable:true,value: "eposta" },
          { text: "İl", value: "il" },
          { text: "İlçe", value: "ilce" },
          // { text: "Onaylandı Mı?", value: "tip" },
        ],
        items: [],
        pagination: {},
        meta: {},
        listSelectedItemIds: [],
        selectedItem: "",
        deleteOperationUrl: "",
        deleteIds: "",
        filterList:[],
        list_length:5,
        openFullTextModal:false,
        openModal: false,
        bayiEkle:false,
      };
    },
    components:{
      Moretext : () =>import("@/components/global/Moretext")
    },
    created() {
      this.search = this.$route.query.search
      this.search = this.search.toLowerCase()
      this.getList();
  
    },
    methods: {
      getList() {
        this.loading = true;
        this.items = [
          {
            id: 1,
            sorumlukisi: "Rasim Karadeniz",
            telefonno: "555 555 55 55",
            eposta: "rasim@dveb.com.tr",
            il: "İstanbul",
            ilce:"Beylikdüzü",
          },
          {
            id: 2,
            sorumlukisi: "Melisa Eldem",
            telefonno: "555 555 55 55",
            eposta:"melisa@dveb.com.tr",
            il: "İstanbul",
            ilce:"Ataşehir",
          },
          {
            id: 3,
            sorumlukisi: "Ahmet Aydın",
            telefonno: "555 555 55 55",
            eposta: "ahmet@devb.com.tr",
            il: "İstanbul",
            ilce:"Sultanbeyli",
          },
          
        ];
        this.totalItems = this.items.length;
  
        this.pagination = {
          current_page: 1,
          total_pages: Math.ceil(this.items?.length/this.list_length),
          total_items: this.items.length,
          list_length: this.list_length
        };
        this.loading = false;
        if (this.search) {
          const searchValue = this.search;
          const foundItems = this.items.filter((item) => {
            for (const prop in item) {
              if (item[prop].toString().toLowerCase().includes(searchValue)) {
                return true;
              }
            }
            return false;
          });
          this.items = foundItems;
        }
  
        // this.items = [];
        // this.totalItems = 0;
  
        // let queryLimit = this.perPage ? this.perPage : 10;
        // let query = `user/list?Page=${
        //   this.page ? this.page : 1
        // }&limit=${queryLimit}`;
        // if (this.search) {
        //   query += `&search=${this.search}`;
        // }
  
        // this.$dbFunctions
        //   .get(query)
        //   .then((res) => {
        //     if (res.success == true && res.count > 0) {
        //       this.items = res.data;
        //       this.totalItems = res.count;
  
        //       this.pagination = {
        //         current_page: res.page,
        //         total_pages: res.pageCount,
        //         total_items: res.count,
        //       };
        //     }
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   })
        //   .finally(() => {
        //     this.loading = false;
        //   });
      },
      bayiEklendi(){
        Swal.fire({
        title: '',
        text: 'Bayi Eklendi!',
        icon: 'success',
        confirmButtonText:"Tamam",
        confirmButtonClass: 'btn btn-danger',
      }).then((result) => {
        console.log(result)
  /* Read more about isConfirmed, isDenied below */
  if (result.isConfirmed) {
    this.bayiEkle = false
  } })
;
      
      },
      updatePage(value) {
        this.meta = value;
  
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },
      openDeleteHandler(key) {
        this.$bvModal.show('modal-deleteOperation');
        let index = this.notes.indexOf(key)
        this.notes.splice(index,1)
      },
    },
    watch: {
      meta: {
        handler() {
          this.getList();
        },
        deep: true,
      },
      search: function () {
        this.getList();
      },
      list_length:function(){
        this.getList()
      },
      $route:function(){
        this.search = this.$route.query.search
      this.search = this.search.toLowerCase()
      }
      
  
    },
   
  };
  </script>
  